"use strict";

class mainController {
	/* @ngInject */
	constructor($scope, $location, sfConfig) {
		this.$location = $location;
		this.$scope = $scope;
		this.tabCommunity = {
			home: {
				name: "Home",
				show: true,
				active: true
			},
			simulador: {
				name: "Simulador",
				show: true,
				active: false
			},
			assistenciaLocaticia: {
				name: 'Assistência Locatícia',
				show: true,
				active: false
			}
		};

		this.sfResource = sfConfig.resource;

		this.message = {};

		this.beginLoad();
		this.listeners();
	}

	changeActiveTab(activeTabName) {
		this.tabCommunity.home.active = activeTabName === "home" ? true : false;
		this.tabCommunity.simulador.active = activeTabName === "simulador" ? true : false;
		this.tabCommunity.assistenciaLocaticia.active = activeTabName === "assistencia-locaticia" ? true : false;
		console.log('Tab ativa: ', activeTabName);
	}

	beginLoad() {
		if (this.$location.path().includes("/home")) {
			this.changeActiveTab("home");
		} else if (this.$location.path().includes("/simulador")) {
			this.changeActiveTab("simulador");
		} else if (this.$location.path().includes("/assistencia-locaticia")) {
			this.changeActiveTab("assistencia-locaticia");
		}
	}

	openModal() {
		$(".modal-content.modal-forgot").addClass("display");
		$(".modal-content.modal-verify").addClass("display");
		$(".modal-content.modal-login").removeClass("display");
	}

	listeners() {
		this.listenShowLoading();
		this.listenErrorMessage();
		this.listenCleanErrorMessage();
		this.listenSuccessMessage();
		this.listenCleanSuccessMessage();
		this.listenCleanMessages();
	}

	listenShowLoading() {
		this.$scope.$on("changeMainShowLoading", (event, status) => {
			event.stopPropagation && event.stopPropagation();

			this.showLoading = status;
		});
	}

	listenErrorMessage() {
		this.$scope.$on("mainErrorMessage", (event, message) => {
			event.stopPropagation && event.stopPropagation();

			this.message.genericError = message;
		});
	}

	listenCleanErrorMessage() {
		this.$scope.$on("mainCleanErrorMessage", (event) => {
			event.stopPropagation && event.stopPropagation();

			this.message.genericError = false;
		});
	}

	listenSuccessMessage() {
		this.$scope.$on("mainSuccessMessage", (event, message) => {
			event.stopPropagation && event.stopPropagation();

			this.message.genericSuccess = message;
		});
	}

	listenCleanSuccessMessage() {
		this.$scope.$on("mainCleanSuccessMessage", (event) => {
			event.stopPropagation && event.stopPropagation();

			this.message.genericSuccess = false;
		});
	}

	listenCleanMessages() {
		this.$scope.$on("mainCleanMessages", (event) => {
			event.stopPropagation && event.stopPropagation();

			this.message.genericError = false;
			this.message.genericSuccess = false;
		});
	}

	cleanMessages() {
		this.message.genericError = false;
		this.message.genericSuccess = false;
	}

	getChangePasswordConfirmationKey() {
		const change_password_key = sessionStorage.getItem("change_password_key");
		return change_password_key;
	}

	getChangePasswordConfirmationMode() {
		const change_password_key = sessionStorage.getItem("change_password_key");
		if (change_password_key) return true;
		else return false;
	}

	showSucessoResetPasswordConfirmed() {
		const reset_password_confirmed = sessionStorage.getItem(
			"reset_password_confirmed"
		);
		if (reset_password_confirmed) return true;
		else return false;
	}
}

module.exports = mainController;
