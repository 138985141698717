'use strict';

const assistenciaLocaticiaController = require('./assistencia-locaticia.controller');
const assistenciaLocaticiaApi = require('./assistencia-locaticia-api.service');

const routesConfiguration = require('./assistencia-locaticia.routes');

const assistenciaLocaticiaModule = angular.module('cap.public.assistencia-locaticia', [
		'ui.router'
	])

	.controller('assistenciaLocaticiaController', assistenciaLocaticiaController)
	.service('assistenciaLocaticiaApi', assistenciaLocaticiaApi)

	.config(routesConfiguration);

module.exports = assistenciaLocaticiaModule;
