'use strict';

class assistenciaLocaticiaController {
	// @ngInject
	constructor($scope, $window, assistenciaLocaticiaApi, feedbackMessages) {
		this.assistenciaLocaticiaApi = assistenciaLocaticiaApi;
		this.$scope = $scope;
		this.$window = $window;
		this.messages = feedbackMessages;

		this.filesToUpload = {
			files: [],
			errFiles: []
		};

		this.getDocumentList();
	}

	getDocumentList() {
		this.$scope.$emit('changeMainShowLoading', true);
		this.assistenciaLocaticiaApi.getDocumentsList().then((response) => {
			console.log('GetDocumentsList', response);
			this.$scope.$emit('changeMainShowLoading', false);
			if (response) {
				this.documentsList = response.documentos;
			}
			this.documentsList = response.documentos;
		});
	}

	hasDocuments() {
		return this.documentsList && this.documentsList.length;
	}

	hasFileToUpload() {
		return this.filesToUpload.files.length > 0;
	}

	getFileAddress(fileUrl) {
		// todo transformar numa constante no angular
		const baseUrl = window.location.origin;
		return baseUrl + fileUrl;
	}

	goToURL(url) {
		console.log('chamando url: ', url);
		window.parent.open(url, '_blank');
	}

	// todo pode por num service
	setFilesToUpload(files, errFiles) {
		if (files.length > 0) {
			console.log('setFilesToUpload', files, errFiles);

			this.setFilesTypes(files);
			const fileType = files[0].fileType.toUpperCase();

			if (fileType !== 'XLS' && fileType !== 'XLSX') {
				const sucessMessage = `Formato inválido (${fileType}), o arquivo deve ser Microsoft Excel (XLS ou XLSX).`;
				this.messages.showErrorMessage(this.$scope, sucessMessage);
			} else {
				this.filesToUpload.files = this.filesToUpload.files.concat(files);
				this.filesToUpload.errFiles = this.filesToUpload.errFiles.concat(errFiles);
			}
		}
	}

	// todo pode por num service
	removeFileToUpload(itemIndex) {
		this.filesToUpload.files.splice(itemIndex, 1);
	}

	// todo pode por num service
	setFilesTypes(files) {
		angular.forEach(files, (file) => {
			file.fileType = file.name.split('.').pop().toLowerCase();
		});
	}

	// todo pode por num service
	clearFileErrors() {
		angular.forEach(this.filesToUpload.files, (file) => {
			file.error = false;
		});
	}

	// todo pode por num service
	uploadFiles() {
		console.log('uploadFiles', this.filesToUpload.files);
		const files = this.filesToUpload.files;
		this.messages.cleanMessages(this.$scope);
		let hasError = false;
		this.clearFileErrors();
		angular.forEach(files, (file, idx) => {
			if (file instanceof File) {
				const isLast = idx === files.length - 1;
				const fileReader = new FileReader();
				file.loader = true;
				fileReader.readAsDataURL(file);
				fileReader.onload = (e) => {
					const fileAsBase64 = e.target.result;
					file.upload = this.assistenciaLocaticiaApi.uploadFiles(file.name, fileAsBase64);
					file.upload.then(
						(response) => {
							console.log('success:', response);
							file.loader = false;
							if (response.isSuccessful) {
								file.result = true;
								if (isLast && !hasError) {
									this.$window.scrollTo(0, 0);
									const successMessage = 'Transmissão em andamento. Aguarde confirmação do resultado da sua transmissão em 24 horas úteis.';
									this.messages.showSuccessMessage(this.$scope, successMessage);
								}
							} else {
								hasError = true;
								file.error = angular.toJson(response.errorMessages[0]);
							}
						},
						(response) => {
							console.log('error:', response);
							file.loader = false;
							hasError = true;
							file.error = angular.toJson(response);
						}
					);
				};
			}
		});
	}
}

module.exports = assistenciaLocaticiaController;
